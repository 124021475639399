
import { Account } from "@/core/models/Account"
import { User } from "@/core/models/User"
import ApiService from "@/core/services/ApiService"

import { computed, defineComponent, ref, toRefs, watch } from "vue"
import { RuleTypes, getRule } from "@/core/helpers/rules"
import { useRouter } from "vue-router"
import { ElForm } from "element-plus"

export default defineComponent({
  name: "account-component",
  components: {},
  props: ["add", "id", "closed"],
  emits: ["closed", "getlist"],
  setup(props, { emit }) {
    const router = useRouter()
    const { add, id } = toRefs(props)

    const accountData = ref<Account>({} as Account)
    type FormInstance = InstanceType<typeof ElForm>
    const ruleFormRef = ref<FormInstance>()

    const rules = ref({
      title: getRule(RuleTypes.TEXT50, "Hesap Adı"),
      "owner.userName": getRule(RuleTypes.TEXT50, "Kullanıcı Adı"),
      "owner.email": getRule(RuleTypes.MAIL),
      "owner.firstName": getRule(RuleTypes.TEXT50, "Ad"),
      "owner.lastName": getRule(RuleTypes.TEXT50, "Soyad"),
      "owner.tcId": getRule(RuleTypes.TCID),
      "owner.birthDate": getRule(RuleTypes.DATE),
    })

    const getAccount = async id => {
      const { data } = await ApiService.get("account/" + id)
      accountData.value = data
    }

    watch(
      () => props.id,
      newValue => {
        if (newValue) {
          ruleFormRef.value?.resetFields()
          getAccount(newValue)
        }
      }
    )

    const showDrawer = computed(() => props.id != undefined || props.add == true)

    watch(
      () => props.add,
      newValue => {
        if (newValue) {
          ruleFormRef.value?.resetFields()
          accountData.value = {} as Account
          accountData.value.owner = {} as User
        }
      }
    )

    async function addAccount() {
      const data = { ...accountData.value }
      await ApiService.post("account/add", data)
      emit("getlist")
      router.push({ name: "account-list" })
    }
    async function updateAccount() {
      const data = { ...accountData.value, id: props.id }
      await ApiService.post("account/edit", data)
      emit("getlist")
      router.push({ name: "account-list" })
    }

    function addOrUpdate(formEl) {
      if (!formEl) return
      formEl.validate(valid => {
        if (valid) {
          if (props.add) {
            addAccount()
          } else {
            updateAccount()
          }
        }
      })
    }

    return {
      showDrawer,
      newAccount: add,
      activeAccountId: id,
      handleClose: props.closed,
      accountData,
      addOrUpdate,
      ruleFormRef,
      rules,
    }
  },
})
