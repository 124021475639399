
import ApiService from "@/core/services/ApiService"
import { useRoute, useRouter } from "vue-router"
import { computed, defineComponent, onMounted, ref, watch } from "vue"
import { Edit, Remove } from "@element-plus/icons-vue"
import { Account } from "@/core/models/Account"
import AccountComponent from "@/components/general/definitions/account/account.vue"
import swalConfirm from "@/core/helpers/swal-confirm"

export default defineComponent({
  name: "account-list",
  components: {
    AccountComponent,
  },
  props: ["add"],
  setup(props) {
    const route = useRoute()
    const router = useRouter()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const accountList = ref<Account[]>([])
    const filterOnlyActives = ref(true)
    const isLoading = ref(true)
    const activeAccountId = ref<string | undefined>()
    const newAccount = ref(false)

    watch(
      () => route.params.account_id,
      newValue => {
        activeAccountId.value = newValue as string
      }
    )

    watch(
      () => props.add,
      newValue => {
        newAccount.value = true == newValue
      }
    )

    const accountListFiltered = computed(() => {
      return accountList.value.filter(x => {
        return x.isActive || !filterOnlyActives.value
      })
    })

    const getAccountList = async () => {
      const { data } = await ApiService.get("account")
      accountList.value = data as Account[]
      isLoading.value = false
    }

    const handleAccountDrawerClose = () => {
      router.push({ name: "account-list" })
    }

    const removeAccount = async account_id => {
      const confirmResult = await swalConfirm()
      if (confirmResult.isConfirmed) {
        await ApiService.get("account/remove/" + account_id)
        await getAccountList()
      }
    }

    onMounted(async () => {
      activeAccountId.value = route.params.account_id as string
      newAccount.value = props.add
      await getAccountList()
    })

    return {
      accountList,
      Edit,
      Remove,
      filterOnlyActives,
      accountListFiltered,
      isLoading,
      getAccountList,
      handleAccountDrawerClose,
      newAccount,
      activeAccountId,
      removeAccount,
    }
  },
})
