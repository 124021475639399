import { Arrayable } from "element-plus/lib/utils"

import { InternalRuleItem } from "async-validator"
import { FormItemRule } from "element-plus"
import _IBAN from "iban"

export enum RuleTypes {
  PHONE,
  TEXT50,
  TEXT100,
  TEXTMAX,
  NUMBER,
  NUMERIC,
  MAIL,
  TCID,
  SELECT,
  RADIO,
  DATE,
  TIME,
  MERSISNO,
  MULTIPLE_SELECT,
  IBAN,
}

export const getRule = (
  type?: RuleTypes,
  itemName?: string,
  additionalValidations?: FormItemRule[]
): Arrayable<FormItemRule> => {
  let rules: Arrayable<FormItemRule>
  const requiredRule = {
    required: true,
    message: `Lütfen ${itemName} Giriniz!`,
    trigger: "blur",
  } as FormItemRule
  switch (type) {
    case RuleTypes.PHONE:
      rules = [
        {
          required: true,
          message: `Lütfen Telefon Giriniz!`,
          trigger: "blur",
        } as FormItemRule,
        {
          validator: validatePhone,
          message: "Geçerli Telefon Giriniz.",
          trigger: "blur",
        } as FormItemRule,
      ]
      break
    case RuleTypes.TEXT50:
      rules = [
        requiredRule,
        {
          min: 0,
          max: 50,
          message: `Lütfen Daha Kısa ${itemName} Giriniz!`,
          trigger: "blur",
        } as FormItemRule,
      ]
      break
    case RuleTypes.MAIL:
      rules = [
        {
          type: "email",
          required: true,
          message: "Lütfen E-mail Adresi Giriniz",
          trigger: "blur",
        } as FormItemRule,
      ]
      break
    case RuleTypes.RADIO:
    case RuleTypes.SELECT:
      rules = [
        {
          required: true,
          message: `Lütfen ${itemName} Seçiniz!`,
          trigger: "change",
        } as FormItemRule,
      ]
      break
    case RuleTypes.TCID:
      rules = [
        {
          validator: validateTCID,
          trigger: "blur",
          required: true,
        } as FormItemRule,
      ]
      break
    case RuleTypes.TEXT100:
      rules = [
        requiredRule,
        {
          min: 0,
          max: 100,
          message: `Lütfen Daha Kısa ${itemName} Giriniz!`,
          trigger: "blur",
        } as FormItemRule,
      ]
      break
    case RuleTypes.TEXTMAX:
      rules = [requiredRule]
      break
    case RuleTypes.MULTIPLE_SELECT:
      rules = [
        {
          type: "array",
          required: true,
          message: `Lütfen ${itemName} Seçiniz!`,
          trigger: "blur",
        } as FormItemRule,
      ]
      break
    case RuleTypes.DATE:
      rules = [
        {
          type: "date",
          required: true,
          message: `Lütfen ${itemName} Tarihi Seçiniz!`,
          trigger: "change",
          //transform: value => {
          //  if (value != undefined) return dayjs(value, "YYYY-MM-DD").format("YYYY/MM/DD")
          //},
        } as FormItemRule,
      ]
      break
    case RuleTypes.NUMERIC:
      rules = [
        requiredRule,
        {
          validator: validateNumericString,
          message: `Lütfen ${itemName} Alanına Sayı Giriniz!`,
          trigger: "blur",
        } as FormItemRule,
      ]
      break
    case RuleTypes.NUMBER:
      rules = [
        requiredRule,
        {
          type: "number",
          message: `Lütfen ${itemName} Alanına Sayı Giriniz!`,
          trigger: "blur",
        } as FormItemRule,
      ]
      break
    case RuleTypes.TIME:
      rules = [
        {
          required: true,
          message: `Lütfen ${itemName} Zamanı Giriniz!`,
          trigger: "change",
        } as FormItemRule,
      ]
      break
    case RuleTypes.MERSISNO:
      rules = [
        {
          required: true,
          message: "Lütfen Mersis Numarası Giriniz!",
          trigger: "blur",
        } as FormItemRule,
        {
          min: 16,
          max: 16,
          message: "Mersis Numarası 16 karakter olmalıdır!",
          trigger: "blur",
        } as FormItemRule,
        {
          pattern: /\d{16}/,
          message: "Lütfen Sayı Giriniz!",
          trigger: "blur",
        } as FormItemRule,
      ]
      break
    case RuleTypes.IBAN:
      rules = [
        {
          validator: validateIBAN,
          trigger: "blur",
          required: true,
        } as FormItemRule,
      ]
      break
    default:
      rules = [{ required: true, message: "Lütfen Giriş Yapınız!", trigger: "blur" }]
  }

  if (additionalValidations) rules.push(...additionalValidations)

  return rules as Arrayable<FormItemRule>
}

export const validateTCID = (
  rule: InternalRuleItem,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  callback: (error?: string | Error | undefined) => void
) => {
  if (value === "") {
    callback(new Error("Lütfen T.C Kimlik Numarası Giriniz!"))
  } else if (isNaN(value)) {
    callback(new Error("T.C Kimlik Numarası Rakam Olmalıdır"))
  }
  value = value + ""
  if (value[0] == "0") {
    callback(new Error("T.C Kimlik Numarası 0 İle Başlayamaz!"))
  } else if (value.toString().length != 11) {
    callback(new Error("T.C Kimlik Numarası 11 Karakter Olmalıdır"))
  } else {
    let tek = 0,
      cift = 0,
      sonuc = 0,
      TCToplam = 0

    tek =
      parseInt(value[0]) +
      parseInt(value[2]) +
      parseInt(value[4]) +
      parseInt(value[6]) +
      parseInt(value[8])
    cift = parseInt(value[1]) + parseInt(value[3]) + parseInt(value[5]) + parseInt(value[7])

    tek = tek * 7
    sonuc = Math.abs(tek - cift)
    if (sonuc % 10 != value[9]) {
      callback(new Error("T.C Kimlik Numarası Geçersiz!"))
    }

    for (let ix = 0; ix < 10; ix++) {
      TCToplam += parseInt(value[ix])
    }

    if (TCToplam % 10 != value[10]) {
      callback(new Error("T.C Kimlik Numarası Geçersiz!"))
    }

    callback()
  }
}

export const validateIBAN = (
  rule: InternalRuleItem,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  callback: (error?: string | Error | undefined) => void
) => {
  if (!_IBAN.isValid(value)) {
    callback(new Error("IBAN Numarası Geçersiz!"))
  }
  callback()
}

export const validatePhone = (
  rule: InternalRuleItem,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  callback: (error?: string | Error | undefined) => void
) => {
  if ((value + "").length !== 15) {
    callback(new Error("10 Haneli Telefon Numaranızı Giriniz!"))
  }
  callback()
}

export const validateNumericString = (
  rule: InternalRuleItem,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  callback: (error?: string | Error | undefined) => void
) => {
  if (isNaN(value)) {
    callback(new Error("Lütfen Sayı Giriniz!"))
  }
  callback()
}
